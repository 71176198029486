import { Component } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { TranslateModule } from "@ngx-translate/core";
import { QRCodeModule } from "angularx-qrcode";
import { TfaSetupComponent } from "./tfa-setup/tfa-setup.component";

import { VexBreadcrumbsComponent } from "@vex/components/vex-breadcrumbs/vex-breadcrumbs.component";
import { VexPageLayoutContentDirective } from "@vex/components/vex-page-layout/vex-page-layout-content.directive";
import { VexPageLayoutHeaderDirective } from "@vex/components/vex-page-layout/vex-page-layout-header.directive";
import { VexPageLayoutComponent } from "@vex/components/vex-page-layout/vex-page-layout.component";
import { VexSecondaryToolbarComponent } from "@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component";

@Component({
  selector: "vex-profile",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTabsModule,

    VexSecondaryToolbarComponent,
    VexBreadcrumbsComponent,
    VexPageLayoutComponent,
    VexPageLayoutHeaderDirective,
    VexPageLayoutContentDirective,

    QRCodeModule,

    TfaSetupComponent
  ],
  templateUrl: "./profile.component.html",
  styleUrl: "./profile.component.scss",
})
export class ProfileComponent {}

import { Injectable, inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticatorService } from "../services/authenticator.service";

@Injectable({
  providedIn: 'root'
})
class PermissionsService {

  constructor(private router: Router, private authenticatorService: AuthenticatorService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticatorService.isLogged) {
      if (next.data['roles']) {
        var hasRole = false;
        if (!hasRole) {
          this.router.navigateByUrl('/auth/login');
          return false;
        }
      }
      return true;
    }
    this.router.navigateByUrl('/auth/login');
    return false;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(PermissionsService).canActivate(next, state);
}
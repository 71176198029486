<vex-secondary-toolbar current="i18n:PAGES.MYSTUDENTS">
	<vex-breadcrumbs [crumbs]="['i18n:PAGES.DASHBOARD', 'i18n:PAGES.MYSTUDENTS']" class="flex-auto"></vex-breadcrumbs>

	<button (click)="loadData()" class="flex-none" mat-icon-button matTooltip="Yenile" type="button">
		<mat-icon svgIcon="mat:refresh"></mat-icon>
	</button>

	@if (calculatePremiumLoading) {
		<mat-spinner class="mr-2" diameter="30" mode="indeterminate" color="accent"></mat-spinner>
	}
	<button
		[disabled]="calculatePremiumLoading"
		(click)="reCalculate()"
		class="flex-none"
		mat-icon-button
		matTooltip="Primleri Yeniden Hesapla"
		type="button"
	>
		<mat-icon svgIcon="mat:calculate"></mat-icon>
	</button>
</vex-secondary-toolbar>

<vex-page-layout mode="card">
	<vex-page-layout-header class="h-6"></vex-page-layout-header>
	<vex-page-layout-content class="container">
		@if (errors && errors.length > 0) {
			<div class="card">
				<div class="p-4">
					<ul>
						@for (error of errors; track error.key) {
							<li class="text-red list-item">
								<b> {{ error.key }} </b>:
								{{ error.value }}
							</li>
						}
					</ul>
				</div>
			</div>
		}

		<div class="card overflow-auto">
			@if (loading) {
				<mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
			}
			@if (!loading && !dataSource.data.length) {
				<p class="p-4 text-center">{{ 'PAGES.NO_STUDENT' | translate }}</p>
			}
			@if (dataSource.data.length) {
				<table @stagger [dataSource]="dataSource" class="w-full" mat-table>
					<ng-container [matColumnDef]="idColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ idColumn.label }}</th>
						<td *matCellDef="let row" class="w-4" mat-cell>#{{ row[idColumn.property] }}</td>
					</ng-container>

					<ng-container [matColumnDef]="utColumn.property">
						<th *matHeaderCellDef mat-header-cell>UT</th>
						<td *matCellDef="let row" mat-cell>
							<span class="font-bold">{{ row.consultantName }}</span> <br />
							<span class="font-normal">{{ row.branchName }}</span>
						</td>
					</ng-container>

					<ng-container [matColumnDef]="studentColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ studentColumn.label }}</th>
						<td *matCellDef="let row" mat-cell>
							<span class="font-bold">{{ row.student.firstName }} {{ row.student.lastName }}</span> <br />
							<span class="font-normal"> {{ row.student.cityName }} </span>
						</td>
					</ng-container>

					<ng-container [matColumnDef]="contactColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ contactColumn.label }}</th>
						<td *matCellDef="let row" mat-cell>
							<span class="font-bold">{{ row.student.email }}</span> <br />
							<span class="font-normal"> {{ row.student.phoneNumber }} </span>
						</td>
					</ng-container>

					<ng-container [matColumnDef]="programTypeColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ programTypeColumn.label }}</th>
						<td *matCellDef="let row" mat-cell>
							<span class="rounded px-2 inline-block" [class]="'program-type-' + row.programType">
								{{ 'PROGRAMTYPE.' + row.programType | translate }}
							</span>
						</td>
					</ng-container>

					<ng-container [matColumnDef]="statusColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ statusColumn.label }}</th>
						<td *matCellDef="let row" mat-cell>
							<span class="rounded px-2 inline-block" [class]="'student-status-' + row.status">
								{{ 'STUDENTSTATUS.' + row.status | translate }}
							</span>
						</td>
					</ng-container>

					<ng-container [matColumnDef]="programCountryColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ programCountryColumn.label }}</th>
						<td *matCellDef="let row" class="w-4" mat-cell>
							@if (row.programCountryName) {
								<span class="font-bold">{{ row.programCountryName }}</span> <br />
							}
							@if (row.programCityName) {
								<span class="font-normal"> {{ row.programCityName }} </span>
							}
						</td>
					</ng-container>

					<ng-container [matColumnDef]="schoolColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ schoolColumn.label }}</th>
						<td *matCellDef="let row" class="w-4 whitespace-nowrap" mat-cell>
							@if (row.schoolName) {
								<span class="font-bold">{{ row.schoolName }}</span> <br />
							}
							@if (row.schoolBranchName) {
								<span class="font-normal"> {{ row.schoolBranchName }} </span>
							}
						</td>
					</ng-container>

					<ng-container [matColumnDef]="premiumColumn.property">
						<th *matHeaderCellDef mat-header-cell>{{ premiumColumn.label }}</th>
						<td *matCellDef="let row" class="w-4" mat-cell>
							@if (row.premiumAmount && row.paid) {
								<div class="rounded px-2 inline-block bg-green-300 text-green-600 font-medium">
									{{ row.premiumAmount | currency: row.premiumCurrencyId }}
								</div>
							}
							@if (row.premiumAmount && !row.paid) {
								<div class="rounded px-2 inline-block bg-amber-300 text-amber-600 font-medium">
									{{ row.premiumAmount | currency: row.premiumCurrencyId }}
								</div>
							}
						</td>
					</ng-container>

					<tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
					<tr
						@fadeInUp
						mat-row
						class="hover:bg-hover transition duration-400 ease-out-swift cursor-pointer"
						*matRowDef="let row; columns: visibleColumns"
					></tr>
					<tr *matNoDataRow>
						<td [attr.colspan]="columns.length">
							<p class="p-4 text-center">{{ 'PAGES.NO_STUDENT' | translate }}</p>
						</td>
					</tr>
				</table>
			}
		</div>
	</vex-page-layout-content>
</vex-page-layout>

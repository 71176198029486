import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { Response } from "src/app/core/models/response.interface";
import { UserResetPassword } from "src/app/core/models/user-login.interface";
import { ApiService } from "src/app/core/services/api.service";
import { AuthenticatorService } from "src/app/core/services/authenticator.service";
import { TranslatorService } from "src/app/core/services/translator.service";

@Component({
  selector: "vex-reset-password",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,

    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent {
  form: UntypedFormGroup = this.fb.group({
    newpassword: ["", Validators.required],
    confirmnewpassword: ["", Validators.required],
  });
  loading = false;

  inputType = "password";
  visible = false;

  state: any = {};
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private authService: AuthenticatorService,
    private apiService: ApiService,
    private translator: TranslatorService,
  ) {
    var currentNavigation = this.router.getCurrentNavigation();
    if (!currentNavigation) {
      this.router.navigateByUrl("/auth/login");
      return;
    }
    if (!currentNavigation.extras.state) {
      this.router.navigateByUrl("/auth/login");
      return;
    }
    this.state = currentNavigation.extras.state;
    console.warn(this.state);
    
  }

  ngOnInit(): void {
    if (!this.state["email"] || !this.state["token"]) {
      this.router.navigateByUrl("/auth/login");
      return;
    }
  }

  submit() {
    if (this.form.value.newpassword !== this.form.value.confirmnewpassword) {
      this.snackbar.open(this.translator.translate("AUTH.PASSWORD_MISMATCH"), this.translator.translate("OK"));
      return;
    }
    this.loading = true;
    var model: UserResetPassword = {
      email: this.state.email,
      password: this.form.value.newpassword,
      token: this.state.token,
    };
    this.apiService.post<Response>(`auth-service/auth/reset-password`, model).subscribe({
      next: (response) => {
        this.snackbar.open(this.translator.translate("AUTH.PASSWORD_RESET_SUCCESS"), this.translator.translate("OK"));
        this.router.navigateByUrl("/auth/login?email=" + this.state.email);
        return;
      },
      error: (error) => {
        this.snackbar.open(error.error.message, this.translator.translate("OK"));
      },
      complete: () => {
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = "password";
      this.visible = false;
      this.cdr.markForCheck();
    } else {
      this.inputType = "text";
      this.visible = true;
      this.cdr.markForCheck();
    }
  }
}

import { AfterContentInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { UtclubFormlyFieldConfig } from 'src/app/core/components/formly/utclub-formly-field.interface';
import { LookupService } from 'src/app/core/services/lookup.service';

@Component({
	selector: 'vex-new-student',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatProgressBarModule,

		FormlyModule,

		VexSecondaryToolbarComponent,
		VexBreadcrumbsComponent,
		VexPageLayoutComponent,
		VexPageLayoutHeaderDirective,
		VexPageLayoutContentDirective
	],
	templateUrl: './new-student.component.html',
	styleUrl: './new-student.component.scss'
})
export class NewStudentComponent {
	constructor(private lookup: LookupService) {}

	form = new FormGroup({});
	model = { email: 'email@gmail.com' };
	fields: UtclubFormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'p-0 grid grid-cols-2 gap-4 mb-4',
			fieldGroup: [
				{
					className: 'col-lg-6',
					key: 'firstName',
					type: 'input',
					props: {
						label: 'Ad',
						required: true
					}
				},
				{
					className: 'col-lg-6',
					key: 'lastName',
					type: 'input',
					props: {
						label: 'Soyad',
						required: true
					}
				}
			]
		},
		{
			fieldGroupClassName: 'p-0 grid grid-cols-2 gap-4 mb-4',
			fieldGroup: [
				{
					className: 'col-lg-6',
					key: 'cityId',
					type: 'cached-select',
					props: {
						label: 'Sehir',
						required: true,
						observable: this.lookup.turkeyCities$
					}
				},
				{
					className: 'col-lg-6',
					key: 'gender',
					type: 'select',
					props: {
						label: 'Cinsiyet',
						required: true,
						options: [
							{ value: 1, label: 'Erkek' },
							{ value: 2, label: 'Kadin' }
						]
					}
				},

				{
					className: 'col-lg-6',
					key: 'email',
					type: 'input',
					props: {
						label: 'Eposta',
						required: true
					}
				},
				{
					className: 'col-lg-6',
					key: 'phoneNumber',
					type: 'input',
					props: {
						label: 'Telefon Numarasi',
						required: true,
						minLength: 10,
						maxLength: 12
					}
				},

				{
					className: 'col-lg-6',
					key: 'educationLevel',
					type: 'select',
					props: {
						label: 'Egitim Seviyesi',
						required: true,
						// TODO - replace with actual data
						options: [
							{ value: 1, label: 'Lise' },
							{ value: 2, label: 'Universite' },
							{ value: 3, label: 'Yuksek Lisans' }
						]
					}
				},
				{
					className: 'col-lg-6',
					key: 'programType',
					type: 'select',
					props: {
						label: 'Form Tipi',
						required: true,
						// TODO - replace with actual data
						options: [
							{ value: 1, label: 'Yaz Okulu' },
							{ value: 2, label: 'Universite' },
							{ value: 3, label: 'Yaz Okulu' }
						]
					}
				},

				{
					className: 'col-lg-6',
					key: 'preferredCountries',
					type: 'cached-select',
					props: {
						label: 'Tercih Edilen Ulkeler',
						multiple: true,
						observable: this.lookup.countries$
					}
				}
			]
		},

		{
			key: 'comment',
			type: 'textarea',
			props: {
				label: 'Yorum',
				rows: 3,
				required: true
			}
		}
	];

	onSubmit(model: any) {
		console.log(model);
	}
}

<vex-secondary-toolbar current="i18n:PAGES.NEWSTUDENT">
	<vex-breadcrumbs [crumbs]="['i18n:PAGES.DASHBOARD', 'i18n:PAGES.NEWSTUDENT']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<vex-page-layout mode="card">
	<vex-page-layout-header class="h-6"></vex-page-layout-header>
	<vex-page-layout-content class="container">
		<div class="card overflow-auto">
			<div class="border-b-2 border-b-primary-500 py-4 px-6 flex items-center">
				<h2 class="m-0 title flex-auto">{{ 'PAGES.NEWSTUDENT' | translate }}</h2>
			</div>
			<div class="p-4">
				<form [formGroup]="form" (ngSubmit)="onSubmit(model)">
					<formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
					<button mat-raised-button class="mt-4" type="submit" color="primary">Submit</button>
				</form>
			</div>
		</div>
	</vex-page-layout-content>
</vex-page-layout>

<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="dropdown-heading"> {{loggedUserName}} </div>
    </div>
  </div>
  
  <div class="dropdown-content">
    <a (click)="close()" routerLink="/profile" class="notification flex items-center" matRipple>
      <mat-icon svgIcon="mat:account_circle" class="notification-icon flex-none text-teal-600"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label"> {{ 'PAGES.PROFILE' | translate }} </div>
        <div class="notification-description"> {{ 'PAGES.PROFILE_DESCRIPTION' | translate }} </div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-end">
    <a (click)="close()" [routerLink]="['/auth/logout']" color="primary" mat-button>Logout</a>
  </div>
</div>
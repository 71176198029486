<div class="w-full h-full bg-pattern flex flex-col items-center justify-center">
  <div @fadeInUp class="card overflow-hidden w-full max-w-md">
    <div class="p-6 pb-0 flex flex-col items-center justify-center">
      <div class="fill-current text-center">
        <img class="h-14" src="/assets/img/logo.png" />
      </div>
    </div>

    <router-outlet></router-outlet>

  </div>
</div>
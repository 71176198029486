import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';

@Component({
  selector: 'vex-auth-layout',
  standalone: true,
  imports: [
    RouterOutlet
  ],
  templateUrl: './auth-layout.component.html',
  styles: [
    `
    .bg-pattern {
      background: linear-gradient(135deg,
          var(--vex-background-background) 22px,
          var(--vex-background-hover) 22px,
          var(--vex-background-hover) 24px,
          transparent 24px,
          transparent 67px,
          var(--vex-background-hover) 67px,
          var(--vex-background-hover) 69px,
          transparent 69px),
        linear-gradient(225deg,
          var(--vex-background-background) 22px,
          var(--vex-background-hover) 22px,
          var(--vex-background-hover) 24px,
          transparent 24px,
          transparent 67px,
          var(--vex-background-hover) 67px,
          var(--vex-background-hover) 69px,
          transparent 69px) 0 64px;
      background-color: var(--vex-background-background);
      background-size: 64px 128px;
    }
    `
  ],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ]
})
export class AuthLayoutComponent { }

import { AfterContentInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { VexBreadcrumbsComponent } from '@vex/components/vex-breadcrumbs/vex-breadcrumbs.component';
import { VexPageLayoutContentDirective } from '@vex/components/vex-page-layout/vex-page-layout-content.directive';
import { VexPageLayoutHeaderDirective } from '@vex/components/vex-page-layout/vex-page-layout-header.directive';
import { VexPageLayoutComponent } from '@vex/components/vex-page-layout/vex-page-layout.component';
import { VexSecondaryToolbarComponent } from '@vex/components/vex-secondary-toolbar/vex-secondary-toolbar.component';

import { fadeInUp400ms } from '@vex/animations/fade-in-up.animation';
import { stagger40ms } from '@vex/animations/stagger.animation';

import { ApiService } from 'src/app/core/services/api.service';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { PagedResponse } from 'src/app/core/models/response.interface';
import { PartnerStudent } from 'src/app/core/models/entities/partner-student.interface';
import { CurrencyPipe } from '@angular/common';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface CalculateEnrollmentPremiumsByUserIdDto {
	ownerType: number;
	ownerId: number;
}

@Component({
	selector: 'vex-my-students',
	standalone: true,
	animations: [fadeInUp400ms, stagger40ms],
	imports: [
		ReactiveFormsModule,
		TranslateModule,
		RouterModule,

		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatIconModule,
		MatTableModule,
		MatPaginatorModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,

		VexSecondaryToolbarComponent,
		VexBreadcrumbsComponent,
		VexPageLayoutComponent,
		VexPageLayoutHeaderDirective,
		VexPageLayoutContentDirective,

		CurrencyPipe,
		TranslateModule
	],
	templateUrl: './my-students.component.html',
	styleUrl: './my-students.component.scss'
})
export class MyStudentsComponent implements AfterContentInit {
	constructor(
		private apiService: ApiService,
		private authenticatorService: AuthenticatorService,
		private matSnackBar: MatSnackBar,
		private cdr: ChangeDetectorRef
	) {}

	loading = false;
	dataSource: MatTableDataSource<PartnerStudent> = new MatTableDataSource<PartnerStudent>();

	idColumn: TableColumn = { label: 'ID', property: 'id', visible: true };
	studentColumn: TableColumn = { label: 'Ogrenci', property: 'student', visible: true };
	statusColumn: TableColumn = { label: 'Durum', property: 'custom', visible: true };
	programTypeColumn: TableColumn = { label: 'Form Tipi', property: 'programType', visible: true };
	contactColumn: TableColumn = { label: 'Iletisim', property: 'contact', visible: true };
	utColumn: TableColumn = { label: 'Ofis', property: 'branch', visible: true };
	// statusColumn: TableColumn = { label: 'Durum', property: 'status', visible: true };
	programCountryColumn: TableColumn = { label: 'Program Ulkesi', property: 'programCountry', visible: true };
	schoolColumn: TableColumn = { label: 'Okul', property: 'school', visible: true };
	premiumColumn: TableColumn = { label: 'Prim', property: 'premium', visible: true };
	columns: TableColumn[] = [
		this.idColumn,
		this.utColumn,
		this.programTypeColumn,
		this.studentColumn,
		this.contactColumn,
		// this.statusColumn,
		this.programCountryColumn,
		this.statusColumn,
		this.schoolColumn,
		this.premiumColumn
	];

	calculatePremiumLoading: boolean = false;
	errors: { key: string; value: string }[] = [];

	ngAfterContentInit(): void {
		this.loadData();
	}

	loadData() {
		this.loading = true;
		this.cdr.detectChanges();
		this.apiService
			.post<PagedResponse<PartnerStudent>>('partner/student-service/student/partner-students', {})
			.subscribe({
				next: (response) => {
					this.dataSource = new MatTableDataSource<PartnerStudent>(response.data);
					this.loading = false;
					this.cdr.detectChanges();
				},
				error: (error) => {
					this.matSnackBar.open('Error fetching students', 'Close', {
						duration: 3000
					});
					this.loading = false;
					this.cdr.detectChanges();
				}
			});
	}

	reCalculate() {
		var ownerType: number = 0;
		var user = this.authenticatorService.userSubject.value;
		if (user == null) {
			this.matSnackBar.open('Kullanici bilgileri alinamadi', 'Kapat', {
				duration: 3000
			});
			return;
		}
		switch (parseInt(user.partnerType.toString())) {
			case 1:
				ownerType = 31;
				break;
			case 2:
				ownerType = 32;
				break;
			case 4:
				ownerType = 34;
				break;
			case 5:
				ownerType = 35;
				break;
			default:
				ownerType = 0;
				break;
		}
		if (ownerType == 0) {
			this.matSnackBar.open('Partner tipi prim hesaplamasi icin uygun degil', 'Kapat', {
				duration: 3000
			});
			return;
		}
		var ownerId: number = 0;
		if (user.partnerId) {
			ownerId = parseInt(user.partnerId.toString());
		}
		if (ownerId == 0) {
			this.matSnackBar.open('Partner id prim hesaplamasi icin uygun degil', 'Kapat', {
				duration: 3000
			});
			return;
		}

		var model: CalculateEnrollmentPremiumsByUserIdDto = {
			ownerType: ownerType,
			ownerId: ownerId
		};

		this.calculatePremiumLoading = true;
		this.cdr.detectChanges();
		this.apiService.post('partner/student-service/enrollment/calculate-premiums', model).subscribe({
			next: (response) => {
				this.matSnackBar.open('Prim hesaplama basarili', 'Kapat', {
					duration: 3000
				});
				this.calculatePremiumLoading = false;
				this.cdr.detectChanges();
			},
			error: (error) => {
				this.matSnackBar.open('Prim hesaplama sirasinda hata olustu', 'Kapat', {
					duration: 3000
				});
				this.calculatePremiumLoading = false;
				if (error.error.errors) {
					this.errors = error.error.errors;
				}
				this.cdr.detectChanges();
			}
		});
	}

	get visibleColumns() {
		return this.columns.filter((column) => column.visible).map((column) => column.property);
	}
}

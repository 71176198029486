import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { MyStudentsComponent } from './pages/my-students/my-students.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { TfaComponent } from './auth/tfa/tfa.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { NewStudentComponent } from './pages/new-student/new-student.component';

export const appRoutes: VexRoutes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				// component: HomeComponent
				redirectTo: 'my-students',
				pathMatch: 'full'
			},
			{
				path: 'profile',
				component: ProfileComponent
			},
			{
				path: 'my-students',
				component: MyStudentsComponent
			},
			{
				path: 'new-student',
				component: NewStudentComponent
			}
		]
	},
	{
		path: 'auth',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'login',
				component: LoginComponent
			},
			{
				path: 'reset-password',
				component: ResetPasswordComponent
			},
			{
				path: 'tfa',
				component: TfaComponent
			},
			{
				path: 'logout',
				component: LogoutComponent
			},
			{
				path: '**',
				redirectTo: 'login'
			}
		]
	}
];

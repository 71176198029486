<div class="text-center mt-4">
  <h2 class="title m-0">
    {{ "AUTH.RESET_PASSWORD_WELLCOME" | translate }}
  </h2>
</div>
<div [formGroup]="form" class="p-6 flex flex-col gap-4">
  <div class="flex flex-col">
    <mat-form-field class="flex-1">
      <mat-label> {{ "AUTH.NEW_PASSWORD" | translate }} </mat-label>
      <input [type]="inputType" formControlName="newpassword" matInput required autocomplete="new-password" />
      <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="Toggle Visibility" type="button">
        @if (visible) {
          <mat-icon svgIcon="mat:visibility"></mat-icon>
        } @else {
          <mat-icon svgIcon="mat:visibility_off"></mat-icon>
        }
      </button>
    </mat-form-field>
    <mat-form-field class="flex-1">
      <mat-label> {{ "AUTH.CONFIRM_NEW_PASSWORD" | translate }} </mat-label>
      <input [type]="inputType" formControlName="confirmnewpassword" matInput required autocomplete="new-password" />
    </mat-form-field>
  </div>
  <button (click)="submit()" color="primary" mat-raised-button type="button">
    {{ "AUTH.RESET_PASSWORD_BUTTON" | translate }}
  </button>
</div>

import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { appRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { provideRouter, withInMemoryScrolling } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatNativeDateModule } from '@angular/material/core';
import { provideIcons } from './core/providers/icons.provider';
import { provideLuxon } from './core/providers/luxon.provider';
import { provideVex } from '@vex/vex.provider';
import { provideNavigation } from './core/providers/navigation.provider';
import { vexConfigs } from '@vex/config/vex-configs';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { tokenInterceptor } from './core/interceptors/token.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CachedSelectFieldComponent } from './core/components/formly/cached-select.field';
import { MatFieldWrapper } from './core/components/formly/mat-field.wrapper';
import { unauthorizedErrorInterceptor } from './core/interceptors/unauthorized-error.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
	providers: [
		importProvidersFrom(
			BrowserModule,
			MatDialogModule,
			MatBottomSheetModule,
			MatNativeDateModule,
			ReactiveFormsModule,
			FormlyModule.forRoot({
				types: [{ name: 'cached-select', component: CachedSelectFieldComponent }],
				wrappers: [{ name: 'mat-field', component: MatFieldWrapper }]
			}),
			FormlyMaterialModule
		),
		provideRouter(
			appRoutes,
			// TODO: Add preloading withPreloading(),
			withInMemoryScrolling({
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled'
			})
		),
		provideAnimations(),
		provideHttpClient(withInterceptorsFromDi(), withInterceptors([tokenInterceptor, unauthorizedErrorInterceptor])),

		provideVex({
			/**
			 * The config that will be used by default.
			 * This can be changed at runtime via the config panel or using the VexConfigService.
			 */
			config: vexConfigs.ikaros,

			/**
			 * Only themes that are available in the config in tailwind.config.ts should be listed here.
			 * Any theme not listed here will not be available in the config panel.
			 */
			availableThemes: [
				{
					name: 'Default',
					className: 'vex-theme-default'
				},
				{
					name: 'Teal',
					className: 'vex-theme-teal'
				},
				{
					name: 'Green',
					className: 'vex-theme-green'
				},
				{
					name: 'Purple',
					className: 'vex-theme-purple'
				},
				{
					name: 'Red',
					className: 'vex-theme-red'
				},
				{
					name: 'Orange',
					className: 'vex-theme-orange'
				}
			]
		}),
		provideNavigation(),
		provideIcons(),
		provideLuxon(),

		TranslateModule.forRoot({
			defaultLanguage: 'en',
			loader: {
				provide: TranslateLoader,
				deps: [HttpClient],
				useFactory: HttpLoaderFactory
			}
		}).providers!
	]
};

import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	constructor(private httpClient: HttpClient) {}

	request<TResponse>(
		method: 'get' | 'post' | 'put' | 'delete',
		url: string,
		body?: any,
		options?: any
	): Observable<HttpEvent<TResponse>> {
		var httpReq = new HttpRequest(method, `${environment.API_URL}api/${url}`, body, options);
		return this.httpClient.request<TResponse>(httpReq);
	}

	get<TResponse>(url: string): Observable<TResponse> {
		return this.httpClient.get<TResponse>(`${environment.API_URL}api/${url}`);
	}

	post<TResponse>(url: string, body: any, options = {}): Observable<TResponse> {
		return this.httpClient.post<TResponse>(`${environment.API_URL}api/${url}`, body, options);
	}

	put<TResponse>(url: string, body: any): Observable<TResponse> {
		return this.httpClient.put<TResponse>(`${environment.API_URL}api/${url}`, body);
	}

	delete(url: string) {
		return this.httpClient.delete<Response>(`${environment.API_URL}api/${url}`);
	}

	getHttpClient(): HttpClient {
		return this.httpClient;
	}
}

import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { stagger40ms } from "src/@vex/animations/stagger.animation";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { AuthenticatorService } from "src/app/core/services/authenticator.service";
import { ApiService } from "src/app/core/services/api.service";
import { Response } from "src/app/core/models/response.interface";
import { AccessToken } from "src/app/core/models/access-token.interface";
import { UserLogin } from "src/app/core/models/user-login.interface";
import { TranslateModule } from "@ngx-translate/core";
import { TranslatorService } from "src/app/core/services/translator.service";

@Component({
  selector: "vex-login",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,

    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
  ],
  templateUrl: "./login.component.html",
  animations: [fadeInUp400ms, stagger40ms],
})
export class LoginComponent implements OnInit {
  form: UntypedFormGroup = this.fb.group({
    email: ["", Validators.required],
    password: ["", Validators.required],
  });
  loading = false;

  inputType = "password";
  visible = false;
  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private authService: AuthenticatorService,
    private apiService: ApiService,
    private translator: TranslatorService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    // if query params has email set email field
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params["email"]) {
        this.form.patchValue({ email: params["email"] });
      }
    });
  }

  submit() {
    this.loading = true;
    var model: UserLogin = {
      email: this.form.value.email,
      password: this.form.value.password,
    };
    this.apiService.post<Response<AccessToken>>(`auth-service/auth/login/partner`, model).subscribe({
      next: (response) => {
        if (response.data.tokenType == "AccessToken") {
          this.authService.setCredentials(response.data);
          this.router.navigateByUrl("/");
          return;
        } else if (response.data.tokenType == "TwoFactorToken") {
          this.snackbar.open(
            this.translator.translate("AUTH.NEED_TWO_FACTOR_AUTHENTICATION"),
            this.translator.translate("OK"),
          );
          this.router.navigateByUrl("/auth/tfa", {
            state: { email: this.form.value.email, password: this.form.value.password },
          });
          return;
        } else if (response.data.tokenType == "ResetPasswordToken") {
          this.snackbar.open(this.translator.translate("AUTH.NEED_RESET_PASSWORD"), this.translator.translate("OK"));
          this.router.navigateByUrl("/auth/reset-password", {
            state: { email: this.form.value.email, token: response.data.token },
          });
          return;
        }
      },
      error: (error) => {
        this.snackbar.open(error.error.message, this.translator.translate("OK"));
      },
      complete: () => {
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
  }

  toggleVisibility() {
    if (this.visible) {
      this.inputType = "password";
      this.visible = false;
      this.cdr.markForCheck();
    } else {
      this.inputType = "text";
      this.visible = true;
      this.cdr.markForCheck();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslatorService } from './core/services/translator.service';
import { AuthenticatorService } from './core/services/authenticator.service';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
  constructor(
    private translatorService: TranslatorService,
    private authenticatorService: AuthenticatorService
  ) { }

  ngOnInit(): void {
    this.translatorService.init();
    this.authenticatorService.init();
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { share, timer, tap, map, ReplaySubject, pipe, Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { AuthenticatorService } from './authenticator.service';
import { Response } from '../models/response.interface';
import { environment } from 'src/environments/environment';
import { Country } from '../models/entities/country.interface';
import { City } from '../models/entities/city.interface';
import { Currency } from '../models/entities/currency.interface';

export const CACHE_TIMEOUT = 1000 * 60 * 60;

@Injectable({
	providedIn: 'root'
})
export class LookupService {
	constructor(private authenticatorService: AuthenticatorService) {}

	getLookupObservable<T>(url: string, cacheTimeout: number = CACHE_TIMEOUT): Observable<T[]> {
		return ajax<Response<T[]>>({
			url: `${environment.API_URL}api/${url}`,
			method: 'GET',
			headers: {
				Authorization: `Bearer ${this.authenticatorService.tokenSubject.value}`
			}
		}).pipe(
			tap(() => console.log('request happened')),
			map((response) => {
				return response.response.data;
			}),
			share({
				connector: () => new ReplaySubject(1),
				resetOnComplete: () => timer(CACHE_TIMEOUT)
			})
		);
	}

	private _countries$!: Observable<Country[]>;
	get countries$() {
		if (!this._countries$) {
			this._countries$ = this.getLookupObservable<Country>('partner/location-service/country');
		}
		return this._countries$;
	}

	private _turkeyCities!: Observable<City[]>;
	get turkeyCities$() {
		if (!this._turkeyCities) {
			this._turkeyCities = this.getLookupObservable<City>('partner/location-service/city?CountryCode=turkey');
		}
		return this._turkeyCities;
	}

	private _currencies!: Observable<Currency[]>;
	get currencies$() {
		if (!this._currencies) {
			this._currencies = this.getLookupObservable<Currency>('partner/accounting-service/currency', CACHE_TIMEOUT);
		}
		return this._currencies;
	}
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatorService } from 'src/app/core/services/authenticator.service';

@Component({
  selector: 'vex-logout',
  standalone: true,
  imports: [],
  template: `Redirecting...`
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticatorService: AuthenticatorService
  ) { }

  ngOnInit() {
    this.authenticatorService.logout();
    this.router.navigateByUrl('/auth/login');
  }
}
